



























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { am_UTC2Local } from "../../helper/utils";
import { Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import UserDisplay from "@/components/common/UserDisplay.vue";

declare const $: any;

@Component({ components: { UserDisplay } })
export default class NavBar extends Vue {
  am_UTC2Local: any;
  selectedAgency: any = "";
  isBiPages: boolean = true;
  agencies: any[] = [];
  // tmpClient: any = []

  constructor() {
    super();
    this.am_UTC2Local = am_UTC2Local;
    if (localStorage.getItem("biagency")) {
      const getbiAgency = JSON.parse(localStorage.getItem("biagency") || "");
      store.dispatch("updateBiSelectedAgency", getbiAgency);
      // this.selectedAgency  = getbiAgency
    }

    if (localStorage.getItem("agency")) {
      const getbiAgency = JSON.parse(localStorage.getItem("agency") || "");
      store.dispatch("updateBiSelectedAgency", getbiAgency);
      this.selectedAgency = getbiAgency;
    }
    this.getAgencies();
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(route: Route) {
    if (route.path.includes("/skr_mandant")) {
      this.isBiPages = false;
    } else if (
      route.path.includes("/einstellungen") ||
      route.path.includes("/skr_editor_uknow")
    ) {
      this.isBiPages = false;
    } else {
      this.isBiPages = true;
    }
  }
  get title() {
    return store.getters.title;
  }

  get icon() {
    return store.getters.icon;
  }

  get year() {
    return store.getters.selectedYear;
  }

  showAgencyModal() {
    $("#select_agency_modal").modal();
  }

  // get agencies() {
  //     return store.getters.agencies
  // }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  setAgency(agency: any) {
    localStorage.setItem("biagency", JSON.stringify(agency));
    localStorage.setItem("agency", JSON.stringify(this.selectedAgency));

    store.dispatch("updateBiSelectedAgency", agency);
  }

  @Watch("selectedAgency")
  onSelectedAgencyChange() {
    const selectedAgency = this.selectedAgency[0];

    localStorage.setItem("biagency", JSON.stringify(selectedAgency));
    store.dispatch("updateBiSelectedAgency", selectedAgency);

    localStorage.setItem("agency", JSON.stringify(selectedAgency));
    store.dispatch("updateSelectedAgency", selectedAgency);
    store.dispatch("updateSelectedClient", null);

    const selectedClient = ((store.state.clients as unknown) as any[]).find(
      (item) => item[0] === selectedAgency
    );
    localStorage.setItem("client", JSON.stringify(selectedClient));
    localStorage.setItem("global_client", JSON.stringify(selectedClient));
    store.dispatch("updateSelectedClient", selectedClient);
    location.reload();
  }

  getAgencies() {
    api.getMandatorstbs().then((res) => {
      const data: [string, string][] = res.data;
      const sortedAgencies = data.sort((a, b) => a[0].localeCompare(b[0]));
      this.agencies = sortedAgencies;

      if (this.agency == null) {
        store.dispatch("updateSelectedAgency", sortedAgencies[0][0]);
      }
    });
  }

  get agency() {
    return store.getters.selectedAgency;
  }

  logout() {
    api.logout();
  }
}
