
















































































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Route } from "vue-router";
import store from "../../store";
import mainAuth from "@/auth";

const isDev = process.env.NODE_ENV === "development";

@Component
export default class SideBar extends Vue {
  @Prop() private msg!: string;
  path: any = "";
  pathUrl: string = "";
  isSideBarOpened = true;
  BI: any = [];
  role = "uknow";
  settingUrl: string = "/einstellungen/mandant/mitarbeiter";

  menu = {
    bi: [
      // {
      //     title: 'Bilanz',
      //     href: 'bilanz'
      // },
      {
        title: "Dashboard",
        href: "dashboard",
      },
      {
        title: "Erfolgsrechnung",
        href: "erfolgsrechnung",
        childs: [
          {
            title: "Umsatz",
            href: "umsatz",
          },
          {
            title: "Kosten",
            href: "kosten",
          },
          {
            title: "Kundenanalyse",
            href: "kundenanalyse",
          },
          {
            title: "Lieferantenanalyse",
            href: "lieferantenanalyse",
          },
          {
            title: "Plandaten",
            href: "plan",
          },
          {
            title: "Liquidität",
            href: undefined,
            childs: [
              {
                title: "OPOS",
                href: "opos",
              },
              {
                title: "Liquiditätsplanung",
                href: "liquiditaetsplanung",
              },
            ],
          },
        ],
      },
      {
        title: "Buchungssätze",
        href: "buchungssaetze",
      },
      localStorage.getItem("msToken") == null
        ? {
            title: "Dokumente",
            href: "dokumente",
          }
        : {
            title: "Dokumente",
            href: "tokenDocs",
          },
    ],
    settings: {
      uknow: [
        {
          title: "u-know Management",
          // href: 'einstellungen/admin/uknow_settings',
          href: "einstellungen/admin/admins",
          level: 0,
          childs: [
            // {
            //     title: 'Allgemeine Einstellungen',
            //     href: 'einstellungen/admin/uknow_settings'
            // },
            {
              title: "Alle u-know Admins",
              href: "einstellungen/admin/admins",
            },
            {
              title: "Alle Benutzer",
              href: "einstellungen/admin/alle-benutzer",
            },
            {
              title: "Alle Kanzleien",
              href: "einstellungen/admin/alle-kanzleien",
            },
            {
              title: "SKR Editor Global",
              href: "skr_editor_uknow",
            },
          ],
        },
        {
          // agency management area
          title: "Kanzlei Management",
          href: "einstellungen/kanzlei/admins",
          level: 2,
          // href: 'einstellungen/kanzlei/generell',
          childs: [
            // {
            //     title: 'Allgemeine Einstellungen',
            //     href: 'einstellungen/kanzlei/generell'
            // },
            {
              title: "Kanzlei Admins",
              href: "einstellungen/kanzlei/admins",
            },
            {
              title: "Kanzlei Viewer",
              href: "einstellungen/kanzlei/mitarbeiter",
            },
            {
              title: "Mandanten",
              href: "einstellungen/kanzlei/mandanten",
            },
            {
              title: "Alle Mandanten Viewer",
              href: "einstellungen/mandant/alle-mandant-Viewer",
            },
          ],
        },
        {
          // (client management area)
          title: "Mandanten Management",
          // href: 'einstellungen/mandant/client_managment',
          // href: 'einstellungen/mandant/client_settings',
          href: "einstellungen/mandant/mitarbeiter",
          level: 3,
          childs: [
            // {
            //     title: 'Allgemeine Einstellungen',
            //     href: 'einstellungen/mandant/client_settings'
            // },
            // {
            //     title: 'Zugewiesene Kanzleimitarbeiter',
            //     href: 'einstellungen/mandant/assinged_agency_employees'
            // },
            // {
            //     title: 'Alle Mandanten Viewer',
            //     href: 'einstellungen/mandant/alle-mandant-Viewer'
            // },
            {
              title: "Mandanten Viewer",
              href: "einstellungen/mandant/mitarbeiter",
            },
            // {
            //     title: 'SKR zuweisen',
            //     href: 'einstellungen/admin/skr_mandant'
            // }
          ],
        },
        // {
        //     // (individual user settings)
        //     title: 'Mein Account',
        //     href: 'einstellungen/client_settings1',
        //     childs: [
        //         {
        //             title: 'Meine Daten',
        //             href: 'einstellungen/client_settings2'
        //         },
        //         {
        //             title: 'Passwort ändern',
        //             href: 'einstellungen/mandant/mitarbeiter'
        //         },
        //     ]
        // }
      ],
    },
  };

  InlineButtonClickHandler(obj: string) {
    if (obj == "tokenDocs" && !mainAuth().isAuthenticated) {
      window.location.href = "/tokenDocs";
    }
  }

  get page() {
    return store.getters.page;
  }

  get permissions() {
    return store.getters.permissions;
  }

  get userLevel() {
    return store.getters.userLevel;
  }

  // @Watch('permissions')
  // onPermissionsChange() {
  //     // Check the permission and hide the menu
  //         // if (this.permissions) {
  //         //     if (Object.keys(this.permissions.stb).length === 0) {
  //         //         this.menu.settings.uknow.splice(1, 1)
  //         //     }
  //         //     if (Object.keys(this.permissions.mandator).length === 0) {
  //         //         this.menu.settings.uknow.splice(0, 1)
  //         //     }
  //         // }

  // }

  @Watch("userLevel")
  onUChange() {
    if (this.userLevel) {
      if (this.userLevel.level == 0) {
        this.settingUrl = "/einstellungen/admin/admins";
      } else if (this.userLevel.level == 1) {
        this.settingUrl = "/einstellungen/kanzlei/admins";
      } else if (this.userLevel.level == 2) {
        this.settingUrl = "/einstellungen/kanzlei/mandanten";
      } else if (this.userLevel.level == 3) {
        this.settingUrl = "/einstellungen/mandant/mitarbeiter";
      }
      let resultMenu = this.menu.settings.uknow.filter((itm) => {
        return itm.level >= this.userLevel.level;
      });

      this.menu.settings.uknow = resultMenu;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(route: Route) {
    this.path = route.name;
    this.pathUrl = route.path.substring(1);
    if (
      route.path.includes("/einstellungen") ||
      route.path.includes("/skr_editor_uknow")
    ) {
      store.dispatch("updatePage", "settings");
    } else {
      store.dispatch("updatePage", "bi");
    }
  }

  isSelected(href: string) {
    return this.pathUrl.startsWith(href);
  }

  isInclude(menu: any) {
    if (menu.href === this.path) {
      return true;
    }
    if (menu && menu.childs) {
      let isExist = false;
      menu.childs.forEach((sub: any) => {
        if (sub.href === this.path) {
          isExist = true;
        }
        if (sub.childs) {
          sub.childs.forEach((s: any) => {
            if (s.href === this.path) {
              isExist = true;
            }
          });
        }
      });
      return isExist;
    } else {
      return false;
    }
  }

  @Emit("onSwitch")
  onSwitch() {
    this.isSideBarOpened = !this.isSideBarOpened;
    return this.isSideBarOpened;
  }

  showGuvTable(): void {
    if (isDev) {
      let guv: any = { title: "Guv", href: "guv" };
      this.BI.splice(1, 0, guv);
    }
  }

  // mounted(){
  //     // this.showGuvTable()

  //     // this.menu.settings.uknow.splice(0, 1)
  //     // this.menu.settings.uknow.splice(1, 1)
  //     // this.menu.settings.uknow.splice(2, 1)
  //     // this.menu.settings.uknow.filter((itm)=>{
  //     //     return itm.level >= 3;
  //     // })
  // }
}
