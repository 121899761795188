































import { Component, Prop, Vue } from "vue-property-decorator";
import NavBar from "@/components/common/NavBar.vue";
import SideBar from "@/components/common/SideBar.vue";
import store from "../store";

import api from "../api";
import { getYears } from "../helper/utils";
import lodash from "lodash";

@Component({ components: { NavBar, SideBar } })
export default class Main extends Vue {
  isSideBarOpened = true;
  showContentOnly = false;
  interval: any = null;

  constructor() {
    super();
    if (
      (location.pathname.includes("bilanz_editor_uknow") ||
        location.pathname.includes("guv_editor_admin")) &&
      !api.getAuth()
    ) {
      this.showContentOnly = true;
    } else {
      this.getMandators();
      this.interval = setInterval(this.getMandators, 10800000);
    }
  }

  mounted() {
    //Get user permissions
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getPermissions()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        store.dispatch("updatePermissions", res.data);
        if (res.data && Object.keys(res.data.stb).length > 0) {
          store.dispatch(
            "updateLoading",
            store.state.loading >= 0 ? store.state.loading + 1 : 1
          );
          api
            .getOwnStbs()
            .then((stb) => {
              store.dispatch("updateClients", stb.data);
              store.dispatch("updateLoading", store.state.loading - 1);
            })
            .catch((err) => {
              store.dispatch("updateLoading", store.state.loading - 1);
            });
        }
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });

    // Get current user level
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getMyLevel()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        store.dispatch("updateUserLevel", res.data);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });

    //Get client mangadors

    let urlHref = window.location.href;
    if (urlHref.indexOf("einstellungen") == -1) {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getConfigClientMandators()
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onSwitch(val: any) {
    this.isSideBarOpened = val;
  }

  getMandators() {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getClients()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        store.dispatch("updateClients", res.data);

        const biSelectedAgency = localStorage.getItem("biagency");
        let selectedClient = JSON.parse(String(localStorage.getItem("client")));

        let filtredAgencies = res.data.map((itm: any) => itm[0]);
        let uniqueFiltredAgencies = Array.from(new Set(filtredAgencies));

        store.dispatch(
          "updateBiSelectedAgency",
          biSelectedAgency ? biSelectedAgency : uniqueFiltredAgencies[0]
        );
        store.dispatch("updateAgencies", uniqueFiltredAgencies);

        if (selectedClient === null) {
          selectedClient = res.data[0];
        }

        let page: any;
        let params: any;

        if (
          localStorage.getItem("routeName") &&
          localStorage.getItem("routeParams")
        ) {
          page = JSON.parse(String(localStorage.getItem("routeName")));
          localStorage.removeItem("routeName");

          params = JSON.parse(String(localStorage.getItem("routeParams")));
          localStorage.removeItem("routeParams");

          // set client, year and comparison based on filter url
          localStorage.setItem("initPlan", JSON.stringify(false));

          // update client and year
          Object.keys(res.data).forEach((key) => {
            // check there are parameters in router
            if (
              Object.keys(params).length != 0 &&
              res.data[key][1] == params["mandant"]
            ) {
              localStorage.setItem("client", JSON.stringify(selectedClient));
              // check year in current mandant
              let years = selectedClient[3];
              Object.keys(years).forEach((key) => {
                if (years[key][0] == params["year"]) {
                  localStorage.setItem("year", JSON.stringify(years[key]));
                }
              });

              // check range step
              if (params["range"] === undefined) {
                params["range"] = "monthly";
              }
              localStorage.setItem("range", JSON.stringify(params["range"]));
              store.dispatch("updateRangeStep", params["range"]);

              // check plan or previous year
              if (params["comparison"] === undefined) {
                params["comparison"] = "vorjahr";
              }
              localStorage.setItem(
                "comparison",
                JSON.stringify(params["comparison"])
              );
              if (params["comparison"] == "vorjahr") {
                store.dispatch("updatePlanSelected", false);
              } else {
                store.dispatch("updatePlanSelected", true);
                localStorage.setItem("initPlan", JSON.stringify(true));
              }
            }
          });
        }

        //Get selected agency
        if (localStorage.getItem("agency")) {
          store.dispatch(
            "updateSelectedAgency",
            JSON.parse(localStorage.getItem("agency") || "")
          );
        }
        if (localStorage.getItem("biagency")) {
          store.dispatch(
            "updateBiSelectedAgency",
            JSON.parse(localStorage.getItem("biagency") || "")
          );
        }

        // if (localStorage.getItem('client')) {
        //     store.dispatch('updateSelectedClient', JSON.parse(localStorage.getItem('client') || ''))
        // } else {
        localStorage.setItem(
          "client",
          JSON.stringify(selectedClient || res.data[0])
        );
        store.dispatch("updateSelectedClient", selectedClient || res.data[0]);
        // }
        getYears();
      })
      .catch((err) => {
        store.dispatch("updateClients", null);
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }
}
