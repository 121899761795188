

















































































































import { mixin as clickaway } from "vue-clickaway";
import store from "@/store";
import Vue from "vue";
import api from "@/api";
import { changeTheme, ThemeType } from "@/helper/themeUtils";

export default Vue.extend({
  mixins: [clickaway],
  data() {
    return {
      open: false,
      selectedTheme: localStorage.getItem("themeType") || "default",
      chartType: localStorage.getItem("chartType") || "bar",
      shortName: "",
      name: "",
      mail: "",
      permission: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    avatarClick() {
      this.open = true;
    },
    awayClick() {
      this.open = false;
    },
    logout() {
      api.logout();
    },
    changeChartType(type: "bar" | "line") {
      store.dispatch("updateChartType", type);
      localStorage.setItem("chartType", type);
      this.chartType = type;
      api.saveUserSettings();
    },
    changeTheme(type: ThemeType) {
      store.dispatch("updateThemeType", type);

      this.selectedTheme = type;
      api.saveUserSettings();
    },
    async getUserInfo() {
      const result = await api.getCallerInfo();
      this.mail = result[1];
      this.shortName = result[2][0] + result[3][0];
      this.name = result[2] + " " + result[3];

      switch (result[4]) {
        case 0:
          this.permission = "Global Admin";
          break;
        case 1:
          this.permission = "Kanzlei Admin";
          break;
        case 2:
          this.permission = "Kanzlei Viewer";
          break;
        case 3:
          this.permission = "Mandant";
          break;
      }
    },
  },
});
